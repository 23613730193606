body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.main {
  flex: 1;
}

.layout {
  width: 100%;
  height: 100%;

  &__body {
    width: 100%;
    height: 100%;
    padding: 0.5rem 0;
  }
}

img:not(.icon) {
  width: 100%;
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
